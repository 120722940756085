<template>
    <Modal title="Політика конфіденційності" max-width="6xl">
        <PrivacyPolicyText/>
    </Modal>
</template>

<script>
import Modal from "@/Components/Modal.vue";
import PrivacyPolicyText from "@/Pages/Pages/Partials/PrivacyPolicyText.vue";

export default {
    components: {PrivacyPolicyText, Modal}
}
</script>
